import React from 'react'

import { requestForWLApplication } from '../../../apiRequests/callToAction'
import { WLApplicationOrderEvent } from '../../../gtm-events'

import SectionContainer from '../../SectionContainer'
import MultiFieldForm from '../../MultiFieldForm'

import componentData from '../../../pages-data/WLPreorderApplication/applicationOrderForm'

import styles from './styles.module.styl'


export default function SectionWLApplicationOrderForm(props) {

    return (
      <SectionContainer className={styles.WLApplicationOrderForm__sectionContainer}>
        <section className={styles.WLApplicationOrderForm}>

          <div className={styles.WLApplicationOrderForm__textBlock}>

            <h1 className={styles.WLApplicationOrderForm__header}>
              {componentData.header}
              {/*<span className={styles.WLApplicationOrderForm__header__tariff}>*/}
              {/*  {props.tariff}*/}
              {/*</span>*/}
            </h1>

            <p className={styles.WLApplicationOrderForm__description}>
              {componentData.description}
            </p>

          </div>

          <div className={styles.WLApplicationOrderForm__divider}></div>

          <MultiFieldForm
            backLink={componentData.backLink}
            className={styles.WLApplicationOrderForm__topIndent}
            ctaAction={requestForWLApplication}
            gtmAction={WLApplicationOrderEvent}
            modalOverlayClassName={styles.WLApplicationOrderForm__modalOverlay}
            agreementWarningClass={styles.WLApplicationOrderForm__agreementWarning}
            additionalInputName
            colorButton='royalBlue'
          />

        </section>
      </SectionContainer>
    )
}
