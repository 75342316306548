import {
	getNameValidState,
	getPhoneValidState,
} from "../../utils/validateFunctions"

import userDataProcessAgreement from "../../pages-data/common/commonData"

export default {
	formFields: {
		name: {
			name: "name",
			label: "Имя",
			type: "text",
			placeholder: "Введите имя",
			validationFunction: getNameValidState,
		},
		phone: {
			name: "phone",
			label: "Телефон",
			placeholder: "(900) 123-45-67",
			validationFunction: getPhoneValidState,
		},
	},
	notificationSuccess: {
		header: "Спасибо!",
		text: "Мы приняли заявку и скоро\n вам позвоним!",
	},
	notificationError: {
		header: "Ошибка на сервере!",
		text: "Пожалуйста, позвоните нам:\n8 800 500-21-38 \n(звонок бесплатный)",
	},
	submitButtonText: "Оставить заявку",
	ctaRequestAgreement: userDataProcessAgreement.ctaRequestAgreement,
	requireAgreementWarning: userDataProcessAgreement.requireAgreementWarning,
}
