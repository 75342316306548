import anchors from "./anchors"

export const TARIFFS_NAMES = {
	LITE: "Lite",
	PUSH: "Push",
	RESERVE: "Reserve",
}

const FEATURES_NAMES = {
	DESIGN: "Брендированный дизайн",
	QR_CODE: "QR-код для авторизации",
	ORDER_HISTORY: "История заказов",
	ADDRESSES: "Ваши адреса",
	ACTIONS: "Акции заведения",
	PUSH_NOTIFICATIONS: "Пуш-уведомления",
	BALANCE_DISPLAY: "Бонусный баланс гостя",
	REVIEWS: "Отзывы",
	PREORDER: "Предзаказы",
	TABLE_RESERVATION: "Бронь столов",
	DELIVERY: "Доставка",
}

export default {
	anchorId: anchors.TARIFFS,
	header: "Мобильное приложение для гостей",
	description:
		"Брендированный дизайн, постоянные обновления, помощь и консультации техподдержки",
	WLApplicationOrderHref: "/WLApplicationOrder",
	priceSymbol: "\u20BD",
	tariffsPricePrefix: "/ месяц",
	selectButtonText: "Выбрать тариф",
	note: "Цена действительна при оплате подписки за год",
	tariffs: [
		{
			name: TARIFFS_NAMES.LITE,
			pricePerMonth: "825",
			description: "Легко авторизируйте посетителей ",
			features: [
				{
					name: FEATURES_NAMES.DESIGN,
					includeInTariff: true,
					// additionalInfo: 1
				},
				{
					name: FEATURES_NAMES.QR_CODE,
					includeInTariff: true,
					// additionalInfo: 'почта и чат'
				},
				{
					name: FEATURES_NAMES.ORDER_HISTORY,
					includeInTariff: true,
				},
				{
					name: FEATURES_NAMES.ADDRESSES,
					includeInTariff: true,
				},
				{
					name: FEATURES_NAMES.ACTIONS,
					includeInTariff: false,
				},
				{
					name: FEATURES_NAMES.REVIEWS,
					includeInTariff: false,
				},
				{
					name: FEATURES_NAMES.BALANCE_DISPLAY,
					includeInTariff: false,
				},
				{
					name: FEATURES_NAMES.PUSH_NOTIFICATIONS,
					includeInTariff: false,
				},
				{
					name: FEATURES_NAMES.PREORDER,
					includeInTariff: false,
				},
				{
					name: FEATURES_NAMES.DELIVERY,
					includeInTariff: false,
				},
				// {
				//   name: FEATURES_NAMES.TABLE_RESERVATION,
				//   includeInTariff: false,
				// },
			],
		},
		{
			name: TARIFFS_NAMES.PUSH,
			pricePerMonth: "2 090",
			description: "Повышайте лояльность гостей",
			features: [
				{
					name: FEATURES_NAMES.DESIGN,
					includeInTariff: true,
					// additionalInfo: 1
				},
				{
					name: FEATURES_NAMES.QR_CODE,
					includeInTariff: true,
					// additionalInfo: 'почта и чат'
				},
				{
					name: FEATURES_NAMES.ORDER_HISTORY,
					includeInTariff: true,
				},
				{
					name: FEATURES_NAMES.ADDRESSES,
					includeInTariff: true,
				},
				{
					name: FEATURES_NAMES.ACTIONS,
					includeInTariff: true,
				},
				{
					name: FEATURES_NAMES.REVIEWS,
					includeInTariff: true,
				},
				{
					name: FEATURES_NAMES.BALANCE_DISPLAY,
					includeInTariff: true,
				},
				{
					name: FEATURES_NAMES.PUSH_NOTIFICATIONS,
					includeInTariff: true,
					newFeature: true,
				},
				{
					name: FEATURES_NAMES.PREORDER,
					includeInTariff: false,
				},
				{
					name: FEATURES_NAMES.DELIVERY,
					includeInTariff: false,
				},
				// {
				//   name: FEATURES_NAMES.TABLE_RESERVATION,
				//   includeInTariff: false,
				// },
			],
		},
		{
			name: TARIFFS_NAMES.RESERVE,
			pricePerMonth: "3 750",
			description: "Захватывайте новую аудиторию",
			features: [
				{
					name: FEATURES_NAMES.DESIGN,
					includeInTariff: true,
					// additionalInfo: 1
				},
				{
					name: FEATURES_NAMES.QR_CODE,
					includeInTariff: true,
					// additionalInfo: 'почта и чат'
				},
				{
					name: FEATURES_NAMES.ORDER_HISTORY,
					includeInTariff: true,
				},
				{
					name: FEATURES_NAMES.ADDRESSES,
					includeInTariff: true,
				},
				{
					name: FEATURES_NAMES.ACTIONS,
					includeInTariff: true,
				},
				{
					name: FEATURES_NAMES.REVIEWS,
					includeInTariff: true,
				},
				{
					name: FEATURES_NAMES.BALANCE_DISPLAY,
					includeInTariff: true,
				},
				{
					name: FEATURES_NAMES.PUSH_NOTIFICATIONS,
					includeInTariff: true,
					newFeature: true,
				},
				{
					name: FEATURES_NAMES.PREORDER,
					includeInTariff: true,
					newFeature: true,
				},
				{
					name: FEATURES_NAMES.DELIVERY,
					includeInTariff: true,
					newFeature: true,
				},
				// {
				//   name: FEATURES_NAMES.TABLE_RESERVATION,
				//   includeInTariff: true,
				//   newFeature: true,
				// },
			],
		},
	],
}
