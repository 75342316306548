import React from "react"
import PropTypes from "prop-types"

import AdaptiveLink from "../AdaptiveLink"

import style from "./styles.module.styl"

export default function ButtonFilled(props) {
	const colorDispatcher = colorType => {
		switch (colorType) {
			case "blue":
				return style.__blue
			case "royalBlue":
				return style.__royalBlue
			case "white":
				return style.__white
			default:
				return style.__gray
		}
	}

	const commonClasses = `
                         ${props.className || ""}
                         ${style.button}
                         ${colorDispatcher(props.colorType)}
                        `

	if (props.handler) {
		return (
			<button
				className={commonClasses}
				disabled={props.disabled}
				// https://github.com/react-toolbox/react-toolbox/issues/1323
				onMouseDown={event => event.preventDefault()}
				onClick={props.handler}
			>
				{props.children}
			</button>
		)
	} else if (props.href.trim()[0] == "/") {
		return (
			<AdaptiveLink
				className={commonClasses}
				href={props.href}
				useGatsbyLink={props.useGatsbyLink}
			>
				{props.children}
			</AdaptiveLink>
		)
	} else {
		return (
			<a
				className={commonClasses}
				href={props.href}
				rel="noopener noreferrer"
				target="_blank"
			>
				{props.children}
			</a>
		)
	}
}

ButtonFilled.propTypes = {
	className: PropTypes.string,
	colorType: PropTypes.string, // цвет кнопки
	disabled: PropTypes.bool,
	href: PropTypes.string,
	useGatsbyLink: PropTypes.bool,
	handler: PropTypes.func,
}
