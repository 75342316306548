import React from 'react'
import { Link } from 'gatsby'

import { pagesLinks } from './links'

export default {
  phone: {
    text: '8 800 500-21-38',
    value: '88005002138',
  },
  emailSupport: 'support@quickresto.ru',
  skype: 'quick.resto',
  buttons: {
    beginFree: {
      text: 'Начать бесплатно',
      link: '/registration/',
    },
  },
  modal: {
    cancelText: 'Отмена',
  },
  phoneMask: '+7 (900) 123-45-67',
  emailMask: 'example@email.com',
  serverErrorScreen: {
    buttonText: 'Вернуться назад',
  },
  ctaRequestAgreement: (
    <>
      Я принимаю
      {' '}
      <Link to={pagesLinks.privacyPolicy.href}>
        условия обработки персональных данных
      </Link>
      {' '}
      и
      {' '}
      <Link to={pagesLinks.lisenceAgreement.href}>
        условия лицензионного соглашения
      </Link>
    </>
  ),
  requireAgreementWarning: 'Требуется согласие с условиями',
  serverErrorText:
		"Ошибка на сервере! Пожалуйста, позвоните нам: <a href='tel:88005002138'>8 800 500-21-38</a> (звонок бесплатный)",
}
