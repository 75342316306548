import React from "react"
import ReactPhoneInput from "react-phone-input-2" // https://github.com/bl00mber/react-phone-input-2
import "react-phone-input-2/dist/style.css"

import styles from "./styles.module.styl"

const PhoneInput = props => {
	const russianNamesOfCountries = {
		am: "Армения",
		az: "Азербайджан",
		by: "Белоруссия",
		ee: "Эстония",
		ge: "Грузия",
		kz: "Казахстан",
		kg: "Киргизстан",
		lv: "Латвия",
		lt: "Литва",
		ru: "Россия",
		tj: "Таджикистан",
		tm: "Туркменистан",
		ua: "Украина",
		uz: "Узбекистан",
		tr: "Турция",
	}

	const availableCountries = [
		"am",
		"az",
		"by",
		"ee",
		"ge",
		"kz",
		"kg",
		"lv",
		"lt",
		"ru",
		"tj",
		"tm",
		"ua",
		"uz",
		"tr",
	]

	return (
		<ReactPhoneInput
			inputClass={`form-control ${
				props.invalid ? styles.__invalid : ""
			} ${props.customInputClass || ""}`}
			inputExtraProps={{ id: "phone" }}
			defaultCountry="ru"
			onlyCountries={availableCountries}
			disableAreaCodes={"true"}
			localization={russianNamesOfCountries}
			value={props.value}
			onChange={value => props.onChange(value)}
			onBlur={props.onBlur}
		/>
	)
}
export default PhoneInput
