import React from 'react'
import PropTypes from 'prop-types'

import Arrow from '../../../assets/svg/arrow.svg'
import Logotype from '../../../assets/svg/logo.svg' //не использовать logotype.svg! Он не появляется в модалке

import AdaptiveLink from '../../AdaptiveLink'

import styles from './styles.module.styl'


export default function SectionWLHeaderInApplicationOrder(props) {
  return(
    <div
      className={`
      ${styles.header}
      ${props.className || ''}
    `}
    >

      <AdaptiveLink
        className={styles.header__indexWLPageLink}
        href={props.backLink.href}
        useGatsbyLink={props.backLink.useGatsbyLink}
      >
        <Arrow/>

        <span
          className={styles.header__back}
          onClick={props.closeModal}
        >
          Вернуться назад
        </span>

        <span
          className={styles.header__mobileBack}
          onClick={props.closeModal}
        >
          Назад
        </span>

      </AdaptiveLink>

      <Logotype
        className={`
          ${styles.header__logo}
          ${props.logoLinkClassName || ''}
      `}
      />

      <div className={styles.header__fictiveBlock}></div>
    </div>
  )
}


SectionWLHeaderInApplicationOrder.propTypes = {
  backLink: PropTypes.object.isRequired, // объект с сылкой и свойством useGatsbyLink для возврата на оределённую страницу
  logoLinkClassName: PropTypes.string,
  closeModal: PropTypes.func,
  className: PropTypes.string,
}
