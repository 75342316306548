import React from "react"

import AllSizedHelmet from "../components/AllSizedHelmet"
import SectionHeaderInWLApplicationOrder from "../components/sections/SectionWLHeaderInApplicationOrder"
import SectionWLApplicationOrderForm from "../components/sections/SectionWLApplicationOrderForm"

import { TARIFFS_NAMES } from "../pages-data/wl/WLTariffs"
import { pagesLinks } from "../pages-data/common/links"

export default class WLApplicationOrder extends React.Component {
	constructor(props) {
		super(props)

		let tariff

		if (props.location.state && props.location.state.tariff) {
			tariff = props.location.state.tariff
		} else {
			tariff = TARIFFS_NAMES.RESERVE
		}

		this.state = {
			tariff: tariff,
		}
	}

	render() {
		return (
			<main>
				<AllSizedHelmet>
					<title>
						Заказать разработку приложения лояльности от Quick Resto
					</title>

					<meta
						name="viewport"
						content="width=device-width, initial-scale=1, maximum-scale=5"
					/>
					<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
					<meta http-equiv="ScreenOrientation" content="autoRotate:disabled" />
					<meta http-equiv="X-UA-Compatible" content="ie=edge" />
					<meta
						name="description"
						content="Закажите разработку мобильного приложения для увеличения лояльности ваших гостей и используйте комплексную систему автоматизации Quick Resto. Бесплатно разработаем для вас брендированное приложение для вашего заведения и предоставим 3 месяца подписки в подарок."
					/>
					{/*<meta name="keywords" content="мобильное приложение, лояльность, гости, тариф, предзаказ, оплата, пуш-уведомления, push" />*/}

					<meta
						property="og:description"
						content="Закажите разработку мобильного приложения для увеличения лояльности ваших гостей и используйте комплексную систему автоматизации Quick Resto. Бесплатно разработаем для вас брендированное приложение для вашего заведения и предоставим 3 месяца подписки в подарок."
					/>
					<meta
						property="og:title"
						content="Заказать разработку приложения лояльности от Quick Resto"
					/>
				</AllSizedHelmet>

				<SectionHeaderInWLApplicationOrder backLink={pagesLinks.indexWL} />

				<SectionWLApplicationOrderForm tariff={this.state.tariff} />
			</main>
		)
	}
}
