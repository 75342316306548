/*global Sentry*/ //https://github.com/octalmage/gatsby-plugin-sentry/issues/20
import React from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"

import PhoneInput from "../PhoneInput"
import ButtonFilled from "../ButtonFilled"
import InputCommon from "../InputCommon"
import SectionWLHeaderInApplicationOrder from "../sections/SectionWLHeaderInApplicationOrder"

import ExclamationIcon from "../../assets/svg/exclamationIcon.svg"
import SquareCheck from "../../assets/svg/icon_chekSquare.svg"
import RedCheck from "../../assets/svg/icon__redCheck.svg"

import { NetError } from "../../apiRequests/utils"

import componentData from "../../pages-data/common/wlOrderForm"

import styles from "./styles.module.styl"
import agreementStyles from "../sections/SectionPhoneCallRequest/styles.module.styl"

// Форма заказа используется только для страниц '/wl' и '/WLApplicationOrder', т.к. для них,
// согласно макету, форма реализует модальное окно в качестве уведомления
export default class MultiFieldForm extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			formValidState: {},
			error: false,
			control: null,
			userData: {
				name: "",
				phone: "",
			},
			agreementStatus: true,
			agreementWarning: false,
		}
	}

	onBlurHandler = async fieldName => {
		// console.log('блюр')

		try {
			const validState = await componentData.formFields[
				fieldName
			].validationFunction(this.state.userData[fieldName])

			this.setState({
				formValidState: {
					...this.state.formValidState,
					[fieldName]: {
						...validState,
						actualValue: this.state.userData[fieldName],
					},
				},
			})
		} catch (e) {
			Sentry.captureException(
				new NetError(
					`Ошибка при запросе на валидацию ${fieldName}: ${e.message}`
				),
				scope => scope.setTag("source", "MultiFieldForm")
			)
		}
	}

	showControl = () => {
		switch (this.state.control) {
			case "notification":
				return (
					<Modal
						isOpen={this.state.control === "notification"}
						bodyOpenClassName={styles.WLOrderForm__notScroll}
						overlayClassName={`
              ${styles.WLOrderForm__modalOverlay}
              ${this.props.modalOverlayClassName || ""}
            `}
						className={styles.WLOrderForm__modalWindow}
					>
						{this.props.modalHeader && (
							<SectionWLHeaderInApplicationOrder
								backLink={this.props.backLink}
								className={this.props.modalHeader}
								closeModal={() =>
									this.setState({
										error: false,
										control: null,
										userData: {
											name: "",
											phone: "",
										},
									})
								}
							/>
						)}

						<div className={styles.WLOrderForm__actionNotification}>
							<h2>
								{this.state.error
									? componentData.notificationError.header
									: componentData.notificationSuccess.header}
							</h2>

							<span>
								{this.state.error
									? componentData.notificationError.text
									: componentData.notificationSuccess.text}
							</span>
						</div>
					</Modal>
				)
			case "spinner":
				return <div className={styles.WLOrderForm__loader}></div>
			default:
				return (
					<ButtonFilled
						colorType={this.props.colorButton || "blue"}
						className={styles.WLOrderForm__submitButton}
						handler={this.actionRequest}
					>
						{this.props.textButton || componentData.submitButtonText}
					</ButtonFilled>
				)
		}
	}

	actionRequest = async () => {
		// устанавливаем спиннер перед запросом к серверу:
		this.setState({
			control: "spinner",
		})

		const newFormValidationState = { ...this.state.formValidState }
		// Поля формы, которые точно нужно свалидировать.
		const fieldsToValidate = ["phone"]

		// Поле 'Имя' нужно валидировать только если передано соответсвующее поле ввода
		if (this.props.additionalInputName) {
			fieldsToValidate.push("name")
		}
		// else {
		// TODO проверить, надо ли удалять поле?
		// delete newFormValidationState.name
		// }

		if (this.state.agreementStatus) {
			try {
				//Валидируем на случай, если не было валидации при событии onBlur:
				for (const fieldName of fieldsToValidate) {
					const currentFieldValue = this.state.userData[fieldName]
					// Для поля нужно изменить состояние валидности:
					// если состояние валидации для поля не определено ИЛИ
					// если значение поля изменилось после крайней валидации
					if (
						!this.state.formValidState[fieldName] ||
						currentFieldValue !==
							this.state.formValidState[fieldName].actualValue
					) {
						newFormValidationState[fieldName] = {
							actualValue: currentFieldValue,
							...(await componentData.formFields[fieldName].validationFunction(
								currentFieldValue
							)),
						}
					}
				}

				this.setState({
					formValidState: newFormValidationState,
				})

				const isFormInvalid = Object.values(newFormValidationState).some(
					validationState => !validationState.valid
				)

				// Если форма валидна...
				if (!isFormInvalid) {
					// ...отправить запрос:
					await this.props.ctaAction(this.state.userData)

					// ...показать уведомление об успешной отправке заявки:
					this.setState({
						control: "notification",
					})

					// ...очистить поля ввода Имени и Телефона:
					this.setState({
						userData: {
							name: "",
							phone: "",
						},
					})

					// ...отправить gtm-событие
					if (this.props.gtmAction) {
						this.props.gtmAction()
					}
				} else {
					// Иначе показать кнопку:
					this.setState({
						control: null,
					})
				}
			} catch (error) {
				Sentry.captureException(new NetError(error), scope =>
					scope.setTag("source", "MultiFieldForm")
				)

				this.setState({
					control: "notification",
					error: true,
				})
			}
		} else {
			this.setState({
				control: null,
				agreementWarning: true,
			})
		}
	}

	renderFormField = props => {
		const isInvalid =
			this.state.formValidState[props.field.name] &&
			this.state.formValidState[props.field.name].valid === false

		return (
			<div className={styles.WLOrderForm__formField}>
				<label
					htmlFor={props.field.name}
					className={styles.WLOrderForm__formFieldLabel}
				>
					{props.field.label}
				</label>

				{props.children ? (
					props.children
				) : (
					<InputCommon
						className={styles.WLOrderForm__formFieldInput}
						extraProps={{
							id: props.field.name,
							autoFocus: props.autoFocus,
							type: props.type,
							onBlur: () => this.onBlurHandler("name"),
						}}
						placeholder={props.field.placeholder}
						invalid={isInvalid}
						value={this.state.userData[props.field.name]}
						changeHandler={value =>
							this.setState({
								userData: {
									...this.state.userData,
									[props.field.name]: value,
								},
							})
						}
					/>
				)}

				{this.state.formValidState[props.field.name] &&
					this.state.formValidState[props.field.name].message && (
						<p
							className={`
                ${styles.WLOrderForm__formFieldMessage}
                ${isInvalid ? styles.__invalid : ""}
              `}
						>
							{isInvalid && <ExclamationIcon />}
							{this.state.formValidState[props.field.name].message}
						</p>
					)}
			</div>
		)
	}

	render() {
		return (
			<div
				className={`
        ${styles.WLOrderForm__formBlock}
        ${this.props.className || ""}
      `}
			>
				<form>
					{/* Поле "Имя" */}
					{this.props.additionalInputName &&
						this.renderFormField({
							field: componentData.formFields.name,
							type: "text",
						})}

					{/* Поле "Телефон" */}
					{this.renderFormField({
						field: componentData.formFields.phone,
						children: (
							<PhoneInput
								customInputClass={styles.WLOrderForm__phoneInput}
								value={this.state.userData.phone}
								onChange={value =>
									this.setState({
										userData: {
											...this.state.userData,
											phone: value,
										},
									})
								}
								onBlur={() => this.onBlurHandler("phone")}
								invalid={
									this.state.formValidState[
										componentData.formFields.phone.name
									] &&
									this.state.formValidState[componentData.formFields.phone.name]
										.valid === false
								}
							/>
						),
					})}
				</form>

				{/* Кнопка или уведомление успеха/ошибки */}
				{this.showControl()}

				{/*Согласие на обработку данных*/}
				<div
					className={`${agreementStyles.phoneCallRequest__agreementWrapper}
                 ${this.props.agreementWarningClass || ""}
                 ${this.state.control === "spinner" ? styles.margin : ""}`}
				>
					{this.state.agreementStatus ? (
						<SquareCheck
							onClick={() =>
								this.setState({
									agreementStatus: false,
								})
							}
						/>
					) : (
						<RedCheck
							onClick={() =>
								this.setState({
									agreementStatus: true,
									agreementWarning: false,
								})
							}
						/>
					)}

					<p
						className={`${agreementStyles.phoneCallRequest__agreement}
             ${styles.WLOrderForm__agreement}`}
					>
						{componentData.ctaRequestAgreement}
					</p>

					<div
						className={`${agreementStyles.phoneCallRequest__agreementWarning}
                   ${this.state.agreementWarning ? "" : agreementStyles.hide}`}
					>
						{componentData.requireAgreementWarning}
					</div>
				</div>
			</div>
		)
	}
}

MultiFieldForm.propTypes = {
	modalHeader: PropTypes.string, // если требуется наличие компонента SectionWLHeaderInApplicationOrder

	// объект с сылкой и свойством useGatsbyLink для проброса в компонент SectionWLHeaderInApplicationOrder.
	// Обязателено, если передаётся свойство "modalHeader"
	backLink: PropTypes.object,
	ctaAction: PropTypes.func.isRequired,
	gtmAction: PropTypes.func,
	modalOverlayClassName: PropTypes.string,
	className: PropTypes.string,
	textButton: PropTypes.string, // текст кнопки
	colorButton: PropTypes.string, // цвет кнопки: blue, royalBlu
	additionalInputName: PropTypes.bool, // дополнительные поле ввода Name
	agreementWarningClass: PropTypes.string, //класс для плашки предупреждения согласия с условиями
}
