import React from "react"

import styles from "./styles.module.styl"

export default function SectionContainer(props) {
	return (
		<div
			id={props.id}
			className={`
      ${props.className || ""}
      ${styles.sectionContainer}
    `}
		>
			{props.children}
		</div>
	)
}
